<template>
  <div v-if="loading" class="loading-container">
    <LoadingOutlined />
  </div>
  <div v-else>
    <div class="d-flex justify-content-center">
      <div
        v-for="(ele, index) in firstTab?.info"
        :key="ele?.title"
        class="mx-4"
      >
        <p class="text-muted d-flex align-items-center justify-content-center">
          <img
            v-if="index === 0"
            style="margin-right: 5px"
            src="../../assets/icons/calendar_icon.svg"
            alt="logo-icon"
          />
          <img
            v-else-if="index === 1"
            style="margin-right: 5px"
            src="../../assets/icons/man_user_icon.svg"
            alt="logo-icon"
          />
          <img
            v-else
            style="margin-right: 5px; width: 24px; height: 24px"
            src="../../assets/icons/man_user_icon.svg"
            alt="logo-icon"
          />
          <span class="lh-1">{{
            ele?.title[store.state.currentLanguage]
          }}</span>
        </p>
        <p class="fw-bold text-primary text-center">
          {{ ele?.value[store.state.currentLanguage] || "-" }}
        </p>
      </div>
    </div>
    <hr />
    <div class="row py-3">
      <div class="col-lg-8">
        <div class="d-flex align-items-center mb-3">
          <FileProtectOutlined class="fs-2 text-primary me-3" />
          <h5 class="m-0">{{ t("team.history") }}</h5>
        </div>
        <div
          v-if="
            teams?.setting_team_informations
              ?.setting_team_information_history_th &&
            store.state.currentLanguage === 'th'
          "
          class="pre"
          v-html="
            teams?.setting_team_informations
              ?.setting_team_information_history_th
          "
        ></div>
        <div
          v-else-if="
            teams?.setting_team_informations
              ?.setting_team_information_history_en &&
            store.state.currentLanguage === 'en'
          "
          class="pre"
          v-html="
            teams?.setting_team_informations
              ?.setting_team_information_history_en
          "
        ></div>
        <div
          v-else-if="
            !teams?.setting_team_informations
              ?.setting_team_information_history_th &&
            !teams?.setting_team_informations
              ?.setting_team_information_history_en
          "
          style="width: 100%; height: 150px"
        >
          <p>{{ t("label.no_data") }}</p>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center text-primary">
            <sat-icon name="shirtTeam" :style="{ fontSize: '2.5em' }" />
            <div class="ms-2 lh-1">
              <h5 class="mb-0">{{ t("team.shirt") }}</h5>
            </div>
          </div>
          <div class="ms-2 lh-1">
            <a-select
              :placeholder="t('team.year')"
              size="large"
              class="me-3 select-style"
              ref="select"
              style="width: 90px; text-align: center"
              :options="years"
              @change="handleChangeYear"
              allow-clear
            >
            </a-select>
          </div>
        </div>
        <a-row
          v-if="uniform.length > 0"
          :gutter="[32, 24]"
          class="content-container"
        >
          <a-col v-for="uni in uniform" :key="uni.created_at">
            <div class="uniform-container">
              <div class="title">
                <p>
                  {{
                    Helpers.uniformType(uni?.setting_team_uniform_type)[
                      store.state.currentLanguage
                    ]
                  }}
                </p>
              </div>
              <div class="body">
                <img :src="uni?.setting_team_uniform_image_url" alt="uniform" />
              </div>
            </div>
          </a-col>
        </a-row>
        <a-empty v-else />
      </div>
      <div class="col-lg-4">
        <div class="d-flex align-items-center mb-3">
          <ContactsOutlined class="fs-2 text-primary me-3" />
          <h5 class="m-0">{{ t("team.contact_information") }}</h5>
        </div>
        <div class="row mb-2">
          <div class="col-4">E-mail</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_email || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">Phone</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_phone || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">Facebook</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_facebook || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">Twitter</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_twitter || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">Instagram</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_instagram || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">Youtube</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_youtube || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">LINE</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_line || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">Website</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_website || "-"
            }}
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Fax</div>
          <div class="col-8">
            {{
              teams?.setting_team_social_contacts
                ?.setting_team_social_contact_fax || "-"
            }}
          </div>
        </div>
        <div class="d-flex align-items-center mb-3">
          <!-- <EnvironmentOutlined class="fs-2 text-primary me-3" /> -->
          <img
            src="../../assets/icons/location_icon.svg"
            alt="address"
            style="margin-right: 10px"
          />
          <h5 class="m-0">{{ t("team.address") }}</h5>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ t("team.address") }}</div>
          <div class="col-8">
            {{
              store.state.currentLanguage === "th"
                ? teams?.setting_team_regions_name
                    ?.setting_stadium_region_address_detail || "-"
                : teams?.setting_team_regions_name
                    ?.setting_stadium_region_address_detail_en || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ t("team.sub_district") }}</div>
          <div class="col-8">
            {{
              store.state.currentLanguage === "th"
                ? teams?.setting_team_regions_name?.sub_district_name_th || "-"
                : teams?.setting_team_regions_name?.sub_district_name_en || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ t("team.district") }}</div>
          <div class="col-8">
            {{
              store.state.currentLanguage === "th"
                ? teams?.setting_team_regions_name?.district_name_th || "-"
                : teams?.setting_team_regions_name?.district_name_en || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ t("team.province") }}</div>
          <div class="col-8">
            {{
              store.state.currentLanguage === "th"
                ? teams?.setting_team_regions_name?.province_name_th || "-"
                : teams?.setting_team_regions_name?.province_name_en || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ t("team.country") }}</div>
          <div class="col-8">
            {{
              store.state.currentLanguage === "th"
                ? teams?.setting_team_regions_name?.country_name_th || "-"
                : teams?.setting_team_regions_name?.country_name_en || "-"
            }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ t("team.zipcode") }}</div>
          <div class="col-8">
            {{
              teams?.setting_team_regions_name?.setting_team_region_zipcode ||
              "-"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  watch,
  onMounted,
  onUpdated,
  computed,
} from "vue";
import {
  CalendarOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { teamService } from "@/_services";
import { Helpers } from "@/_modules/helpers";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  name: "Tab1",
  props: {
    firstTab: {
      type: Object,
      require: true,
    },
    teamId: {
      type: Number,
      require: true,
    },
    routes: {
      type: Object,
    },
  },
  components: {
    CalendarOutlined,
    FileProtectOutlined,
    ContactsOutlined,
    EnvironmentOutlined,
    LoadingOutlined,
  },
  setup(props) {
    const { firstTab, routes, teamId } = props;
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const state = reactive({
      loading: true,
      teams: {},
      uniformFull: [],
      uniform: [],
      years: [],
      selectedYear: null,
    });

    const { run: getTeamRepository } = useRequest(teamService.getById, {
      manual: true,
      formatResult: ({ teams }) => {
        state.teams = teams;
      },
    });

    const { run: getUniform } = useRequest(teamService.getUniform, {
      manual: true,
      formatResult: ({ teams }) => {
        state.uniform = teams;
        state.uniformFull = teams;
        state.years = Helpers.sortArrayByKey(
          Helpers.uniqueArray(
            teams.map((t, index) => {
              return {
                value: +t.setting_team_year,
                label: t.setting_team_year,
                key: `${index + 1}-${t.setting_team_year}`,
              };
            }),
            "value"
          ),
          "value"
        );
      },
    });

    onMounted(async () => {
      try {
        await getTeamRepository(+teamId);
        await getUniform(+teamId);
      } catch (error) {
        // console.log("Tab1 :: catch", { state, error });
      } finally {
        state.loading = false;
        routes[1].breadcrumbName =
          store.state.currentLanguage === "th"
            ? state?.teams?.setting_team_informations
                ?.setting_team_information_name_th
            : state?.teams?.setting_team_informations
                ?.setting_team_information_name_en;
        firstTab.info = firstTab.info.map((info) => {
          const { key } = info;
          switch (key) {
            case "established":
              info = {
                ...info,
                value: {
                  en: state.teams?.setting_team_informations
                    ?.setting_team_information_established,
                  th: state.teams?.setting_team_informations
                    ?.setting_team_information_established,
                },
              };
              break;
            case "nickname":
              info = {
                ...info,
                value: {
                  en: state.teams?.setting_team_informations
                    ?.setting_team_information_nickname_en,
                  th: state.teams?.setting_team_informations
                    ?.setting_team_information_nickname,
                },
              };
              break;
            case "country":
              info = {
                ...info,
                value: {
                  en: state.teams?.setting_team_regions_name?.country_name_en,
                  th: state.teams?.setting_team_regions_name?.country_name_th,
                },
              };
              break;
            default:
              break;
          }
          return info;
        });
      }
    });

    const handleChangeYear = (value, option) => {
      state.selectedYear = value;
      if (value) {
        state.uniform = state.uniformFull.filter(
          (uni) => `${uni.setting_team_year}` === `${value}`
        );
      } else {
        state.uniform = state.uniformFull;
      }
    };

    onUpdated(() => {
      // console.log("onUpdated Tab1 :: ", state);
    });

    return {
      t,
      store,
      firstTab,
      ...toRefs(state),
      handleChangeYear,
      Helpers,
    };
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0px auto;
}
:deep(.sat-select:not(.sat-select-customize-input) .sat-select-selector) {
  border-radius: 25px;
}

.uniform-container {
  //   border: 1px solid black;
  border-radius: 20px;
  width: 200px;
  height: 300px;
  //   background: linear-gradient(180deg, #b9b9c3 0%, #ffffff 58.33%, #b9b9c3 100%);
  background: linear-gradient(180deg, #f0f0f0 0%, #ffffff 60%, #f0f0f0 100%);

  //   padding: 20px;
  text-align: center;
  .title {
    padding: 10px;
    font-weight: 600;
  }
  .body {
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      // width auto;
      max-width: 100%;
      height: auto;
      max-height: 200px;
    }
  }
}
.content-container {
  padding-top: 20px;
}
// .flex-container {
//     display: flex;
// }
// .wrap    {
//   -webkit-flex-wrap: wrap;
//   flex-wrap: wrap;
// }
</style>;
