
import { defineComponent, reactive, toRefs, ref, watch, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import {
  CalendarOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons-vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import { athleteService, teamService } from "@/_services";
import {
  Athlete,
  BaseResponse,
  BreadcrumbRoute,
  IAthlete,
  IStadium,
  IStaff,
  ITeam,
} from "@/_modules/types";
import useTeamRepositories from "@/_composables/useTeamRepositories";
import TeamMember from "@/views/team/TeamMember.vue";
import Tab1 from "./Tab1.vue";
import Tab2 from './Tab2.vue'
import Tab3 from './Tab3.vue'
import Tab4 from './Tab4.vue'
import Tab5 from './Tab5.vue'
import { useStore } from "vuex";

export default defineComponent({
  name: "Team",
  components: {
    SatIcon,
    SatCardCarousel,
    SatBreadcrumb,
    CalendarOutlined,
    FileProtectOutlined,
    ContactsOutlined,
    EnvironmentOutlined,
    TeamMember,
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5
  },
  setup() {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      loading: true,
      // team: {} as ITeam,
      tabs:{
        th:[{ type: "ประวัติ", title: "ข้อมูลทีม" },
        { type: "ประวัติ", title: "นักกีฬาภายในทีม" },
        { type: "ประวัติ", title: "บุคลากรภายในทีม" },
        { type: "ประวัติ", title: "การแข่งขัน" },
        { type: "คลัง", title: "ภาพและวิดีโอ" },],
        en:[{ type: "Record", title: "Team Information" },
        { type: "Record", title: "List of Players" },
        { type: "Record", title: "List of Staffs" },
        { type: "Record", title: "Competition" },
        { type: "", title: "Images & Videos" },]
      },
      firstTab: {
        info: [
          {
            key: "established",
            title: {
              en: "Established Year",
              th: "ปีที่ก่อตั้ง",
            },
            icon: "SatIcon",
            props: { name: "hearthCalendar", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "nickname",
            title: {
              en: "Nickname",
              th: "ฉายา",
            },
            icon: "SatIcon",
            props: { name: "manCircle", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "country",
            title: {
              en: "Country",
              th: "ประเทศ",
            },
            icon: "EnvironmentOutlined",
            props: { class: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
        ],
        content: "",
        address: "",
      },
      banner: {},
      teams:{}
    });
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "teams",
        breadcrumbName: t("label.overview.teams"),
      },
      {
        breadcrumbName: "-",
      },
    ]);

    const { run: getBannerById } = useRequest(teamService.getBannerById, {
      manual: true,
      onSuccess: (formattedData) => {
        state.banner = formattedData.teams;
      },
    });

    const { run: getTeamRepository } = useRequest(teamService.getById, {
      manual: true,
      formatResult: ({ teams }) => {
        state.teams = teams;
      },
    });
    onMounted(async () => {
      try {
        getBannerById(+route.params.id);
        getTeamRepository(+route.params.id);

      } catch (error) {
        // console.log("Team :: catch ", error);
      } finally {
        // console.log("Team :: finally", state);
      }
    });

    onUpdated(() => {
      // console.log('Team onUpdated :: ', state)
    })

    // watch(
    //   () => route.params.id,
    //   async (newId) => {
    //     if (Number.isNaN(+newId)) return;
    //     const result = await getTeamRepository(+newId);
    //     if (result) {
    //       state.team = result;
    //       routes.value[1].breadcrumbName = state.team.name.th;
    //       state.firstTab.info.forEach((e, idx, arr) => {
    //         const { key } = e;
    //         switch (key) {
    //           case "established":
    //             arr[idx].value = state.team.name.established ?? "-";
    //             break;
    //           case "nickname":
    //             arr[idx].value = state.team.name.nickname;
    //             break;
    //           case "country":
    //             arr[idx].value = state.team.country_th;
    //             break;
    //         }
    //       });
    //     }
    //   },
    //   { immediate: true }
    // );

    return {
      t,
      store,
      ...toRefs(state),
      routes,
      activeKey: ref(0),
      teamId: route.params.id,
    };
  },
});
