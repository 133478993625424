<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <sat-icon name="athleteBadge" :style="{ fontSize: '2.5em' }" />
      <h5 class="ms-2 lh-1">
        {{t('team.current_staff')}}
        <label v-if="store.state.currentLanguage === 'th'">{{
          banner?.global_config_local_types?.global_config_local_type_name_th
            ? ` (${banner?.global_config_local_types?.global_config_local_type_name_th})`
            : ""
        }}
        </label>
        <label v-else>
        {{
          banner?.global_config_local_types?.global_config_local_type_name_en
            ? ` (${banner?.global_config_local_types?.global_config_local_type_name_en})`
            : ""
        }}
        </label>
      </h5>
    </div>
    <sat-search-input
      advance-search="team-tab3"
      color="#0F4C82"
      class="mb-5"
      :placeHolder="t('team.search_staff')"
      @search="onFetchTeamsStaffCurrent"
    />
    <div>
      <div v-if="loadingStaffCurrent" class="loading-container">
        <LoadingOutlined />
      </div>
      <a-row
        v-else-if="staffCurrent?.data?.length > 0"
        :gutter="[40, 40]"
        align="right"
      >
        <a-col v-for="staff in staffCurrent?.data" :key="staff.id">
          <div class="staff-container" :onClick="() => onClickCard(staff)">
            <div class="banner">
              <img
                v-if="
                  staff?.accounts.account_informations
                    ?.account_media_preview_url
                "
                :src="
                  staff?.accounts.account_informations
                    ?.account_media_preview_url
                "
                alt="banner"
              />
              <img
                v-else
                src="../../assets/staff-placeholder.svg"
                alt="banner"
                style="width: 190px"
              />
              <!-- staff-placeholder -->
            </div>
            <div class="body">
              <div class="text-space">
                <h5>
                  {{
                    `${staff?.accounts.account_informations?.account_information_firstname_th} ${staff?.accounts.account_informations?.account_information_lastname_th}`
                  }}
                </h5>
                <span>{{
                  store.state.currentLanguage === 'th'? staff?.accounts.account_informations
                    ?.account_information_nationality:staff?.accounts.account_informations
                    ?.account_information_nationality_en
                }}</span>
              </div>
              <div class="text-space">
                <span>
                  {{
                    `${staff?.accounts.account_informations?.account_information_firstname_en} ${staff?.accounts.account_informations?.account_information_lastname_en}`
                  }}
                </span>
                <span>{{t('team.age')}}{{
                  ` ${
                    staff?.accounts.account_informations
                      ?.account_information_age || " "
                  }`
                }} {{t('team.year') }}</span>
              </div>
              <div style="text-align: left; margin-top: 10px">
                <p class="text-blue">
                  {{ store.state.currentLanguage === 'th'?staff?.global_config_position_name_th:staff?.global_config_position_name_en }}
                </p>
                <p class="text-blue">
                  {{ store.state.currentLanguage === 'th'?staff?.global_config_department_name_th:staff?.global_config_department_name_en }}
                </p>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else />
      <a-divider />
      <div class="pagination-container">
        <p>
          {{ `1-${staffCurrent?.perPage} ${t("label.table.of")} ${staffCurrent?.total} ${t("label.table.items")}` }}
        </p>
        <a-pagination
          :current="currentPageCurrent"
          :page-size-options="pageSizeOptions"
          :total="staffCurrent?.total"
          show-size-changer
          :page-size="staffCurrent.perPage"
          @change="onPageChangeCurrent"
          @showSizeChange="onShowSizeChangeCurrent"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== staffCurrent?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3" style="margin-top: 30px">
      <sat-icon name="athleteBadge" :style="{ fontSize: '2.5em' }" />
      <h5 class="ms-2 lh-1">
        {{t('team.list_staff')}}
        <label v-if="store.state.currentLanguage === 'th'">{{
          banner?.global_config_local_types?.global_config_local_type_name_th
            ? ` (${banner?.global_config_local_types?.global_config_local_type_name_th})`
            : ""
        }}
        </label>
        <label v-else>
        {{
          banner?.global_config_local_types?.global_config_local_type_name_en
            ? ` (${banner?.global_config_local_types?.global_config_local_type_name_en})`
            : ""
        }}
        </label>

      </h5>
    </div>
    <sat-search-input
      advance-search="team-tab3"
      color="#0F4C82"
      class="mb-5"
      :placeHolder="t('team.search_staff')"
      @search="onFetchTeamsStaffHistory"
    />
    <div>
      <div v-if="loadingStaffHistory" class="loading-container">
        <LoadingOutlined />
      </div>
      <a-row
        v-else-if="staffHistory?.data?.length > 0"
        :gutter="[32, 32]"
        align="right"
      >
        <a-col v-for="staff in staffHistory?.data" :key="staff.id">
          <div class="staff-container">
            <div class="banner">
              <img
                v-if="
                  staff?.accounts.account_informations
                    ?.account_media_preview_url
                "
                :src="
                  staff?.accounts.account_informations
                    ?.account_media_preview_url
                "
                alt="banner"
              />
              <img
                v-else
                src="../../assets/staff-placeholder.svg"
                alt="banner"
                style="width: 190px"
              />
              <!-- staff-placeholder -->
            </div>
            <div class="body">
              <div class="text-space">
                <h5>
                  {{
                    `${staff?.accounts.account_informations?.account_information_firstname_th} ${staff?.accounts.account_informations?.account_information_lastname_th}`
                  }}
                </h5>
                <span>{{
                  store.state.currentLanguage === 'th'? staff?.accounts.account_informations
                    ?.account_information_nationality:staff?.accounts.account_informations
                    ?.account_information_nationality_en
                }}</span>
              </div>
              <div class="text-space">
                <span>
                  {{
                    `${staff?.accounts.account_informations?.account_information_firstname_en} ${staff?.accounts.account_informations?.account_information_lastname_en}`
                  }}
                </span>
                <span>{{t('team.age')}}{{
                  ` ${
                    staff?.accounts.account_informations
                      ?.account_information_age || "-"
                  }`
                }} {{t('team.year') }}</span>
              </div>
              <div style="text-align: left; margin-top: 10px">
                <p class="text-blue">
                  {{ store.state.currentLanguage === 'th'?staff?.global_config_position_name_th:staff?.global_config_position_name_en }}
                </p>
                <p class="text-blue">
                  {{ store.state.currentLanguage === 'th'?staff?.global_config_department_name_th:staff?.global_config_department_name_en }}
                </p>
              </div>

            </div>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else />
      <a-divider />
      <div class="pagination-container">
        <p>
          {{ `1-${staffHistory?.perPage} ${t("label.table.of")} ${staffHistory?.total} ${t("label.table.items")}` }}
        </p>
        <a-pagination
          :current="currentPageHistory"
          :page-size-options="pageSizeOptions"
          :total="staffHistory?.total"
          show-size-changer
          :page-size="staffHistory.perPage"
          @change="onPageChangeHistory"
          @showSizeChange="onShowSizeChangeHistory"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== staffHistory?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatIcon from "@/components/SatIcon.vue";
import { useRequest } from "vue-request";
import { teamService } from "@/_services";
import { onMounted, reactive, toRefs } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  components: {
    SatSearchInput,
    SatIcon,
    LoadingOutlined,
  },
  props: {
    teamId: {
      type: String,
      require: false,
    },
    banner: {
      type: Object,
      require: false,
    },
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const { teamId, banner } = props;
    const router = useRouter()
    const state = reactive({
      loadingStaffCurrent: true,
      loadingStaffHistory: true,
      staffCurrent: {},
      staffHistory: {},
      currentPageCurrent: 1,
      currentPageHistory: 1,
      pageSizeOptions: ["8", "20", "40"],
      name: "",
    });

    const { run: getTeamsStaffCurrent } = useRequest(
      teamService.getTeamsStaffCurrent,
      { manual: true }
    );
    const { run: getTeamsStaffHistory } = useRequest(
      teamService.getTeamsStaffHistory,
      { manual: true }
    );

    onMounted(async () => {
      try {
        onFetchTeamsStaffCurrent({
          page: state.currentPageCurrent,
          size: state.pageSizeOptions[0],
        });
        onFetchTeamsStaffHistory({
          page: state.currentPageHistory,
          size: state.pageSizeOptions[0],
        });
      } catch (error) {
        // console.log("tab3 catch :: ", error);
      } finally {
        // console.log("tab3 finally :: ", state);
        // console.log("tab3 finally :: banner :: ", banner);
      }
    });

    const onFetchTeamsStaffCurrent = async (params) => {
      state.loadingStaffCurrent = true;
      const { isSearch, ...other } = params;
      if (isSearch) {
        state.name = params.name;
      }
      const res = await getTeamsStaffCurrent(teamId, {
        ...other,
      });
      state.staffCurrent = res.team_staff_history;
      state.loadingStaffCurrent = false;
    };
    const onFetchTeamsStaffHistory = async (params) => {
      state.loadingStaffHistory = true;
      const { isSearch, ...other } = params;
      if (isSearch) {
        state.name = params.name;
      }
      const res = await getTeamsStaffHistory(teamId, {
        ...other,
      });
      state.staffHistory = res.team_staff_history;
      state.loadingStaffHistory = false;
    };

    const onPageChangeCurrent = (page, pageSize) => {
      state.currentPageCurrent = page;
      onFetchTeamsStaffCurrent({ page, size: pageSize });
    };

    const onShowSizeChangeCurrent = (current, pageSize) => {
      state.currentPageCurrent = current;
      onFetchTeamsStaffCurrent({ page: current, size: pageSize });
    };

    const onPageChangeHistory = (page, pageSize) => {
      state.currentPageHistory = page;
      onFetchTeamsStaffHistory({ page, size: pageSize });
    };

    const onShowSizeChangeHistory = (current, pageSize) => {
      state.currentPageHistory = current;
      onFetchTeamsStaffHistory({ page: current, size: pageSize });
    };

    const onClickCard = (data) => {
      console.log("onClickCard :: ", data);
      router.push(`/staffs/${data.accounts.account_id}`)
    };

    return {
      t,
      store,
      ...toRefs(state),
      onPageChangeCurrent,
      onShowSizeChangeCurrent,
      onPageChangeHistory,
      onShowSizeChangeHistory,
      onFetchTeamsStaffCurrent,
      onFetchTeamsStaffHistory,
      onClickCard,
    };
  },
};
</script>

<style lang="less" scoped>
h5 {
  margin: 5px 0px;
}
.staff-container {
  cursor: pointer;
  width: 280px;
  //   height: 300px;
  border-radius: 10px;
  //   text-align: center;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .banner {
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    height: 200px;
    background: linear-gradient(
      180deg,
      rgba(0, 53, 50, 0.72) 0%,
      rgba(0, 51, 45, 0.34) 100%
    );
  }
  .body {
    height: 160px;
    width: 100%;
    padding: 10px 20px;
    .text-space {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-blue {
      color: #0f4c82;
      margin: 0px;
    }
  }
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  p {
    margin: auto 0px;
  }
}
</style>